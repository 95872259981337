var millesime = $('#vin-millesimes').val();

$('#millesime-'+millesime).addClass('active');
$('#vin-photo-'+millesime).addClass('active');

$('#vin-millesimes').on('change', function(){
  var millesimeUpdate = $('#vin-millesimes').val();

  $('.millesime-content').removeClass('active');
  $('#millesime-'+millesimeUpdate).addClass('active');

  $('.vin-photo-content').removeClass('active');
  $('#vin-photo-'+millesimeUpdate).addClass('active');
});

