$(".scroll-to-next").on("click", function() {
  $(document).scrollTop($(this).parent().next().offset().top);
  return false;
});

/* Actus postnavigation scroll to content */
let postNavLinks = document.querySelectorAll('.posts-nav a');
postNavLinks.forEach( (link) => {
  let linkHref = link.getAttribute('href');
  link.setAttribute('href', linkHref + '#article-content');
});