$('.header-img-link').hover(function(){
  var classId = $(this).attr('class').split(/[ -]+/)[1];
  var imgLinked = $('.img-header-' + classId);
  imgLinked.addClass('active');
}, function(){
  var classId = $(this).attr('class').split(/[ -]+/)[1];
  var imgLinked = $('.img-header-' + classId);
  imgLinked.removeClass('active');
});

$('.single_add_to_cart_button').on('click', function(){
  $('body').addClass('scroll-up');
  $(this).blur();
});

// MàJ auto du panier quand la qté change
var timeout;
$('.woocommerce').on('change', 'input.qty', function(){
  if ( timeout !== undefined ) {
      clearTimeout( timeout );
  }
  timeout = setTimeout(function() {
      $("[name='update_cart']").trigger("click");
  }, 500 );
});
