const $window = $(window);
const body = document.body;
const scrollUp = "scroll-up";
const scrollDown = "scroll-down";
const scrolledTop = "scrolled-top";
let lastScroll = 0;

let stickyHeader = () => {
	const currentScroll = window.pageYOffset;
	if (currentScroll <= 150) {
		body.classList.remove(scrollUp);
		body.classList.add(scrolledTop);
	} else {
		body.classList.remove(scrolledTop);
	}

	if (currentScroll > lastScroll && !body.classList.contains(scrollDown)) {
		// down
		body.classList.remove(scrollUp);
		body.classList.add(scrollDown);
	} else if (currentScroll < lastScroll && body.classList.contains(scrollDown)) {
		// up
		body.classList.remove(scrollDown);
		body.classList.add(scrollUp);
	}
	lastScroll = currentScroll;
};

$window.on('load scroll', () => {
	stickyHeader();
});
