import Swiper from 'swiper/bundle';

/* eslint-disable no-unused-vars */

/* home slider with custom icon navigation */
var home_swiper_picto = new Swiper('.home-swiper-picto', {
  slidesPerView: 3,
});

var home_swiper = new Swiper('.home-swiper', {
  loop: false,
  effect: 'fade',
  autoplay: {
    delay: 3000,
  },
  speed: 1000,
  disableOnInteraction: false,
  thumbs: {
    swiper: home_swiper_picto,
  },
});

home_swiper.on('slideChangeTransitionStart', function () {
  $('.home-swiper .swiper-wrapper').addClass('is-animate');
});
home_swiper.on('slideChangeTransitionEnd', function () {
  $('.home-swiper .swiper-wrapper').removeClass('is-animate');
});

/* sliders text-slide */
const swiperContainers = document.querySelectorAll('.text-slide-images');
const swipers = document.querySelectorAll('.text-slide-images .text-slide-swiper');

swipers.forEach((swiper, index) => {
  swiper.classList.add(`swiper-${index}`);
});

swiperContainers.forEach((container, index) => {
  container.querySelector('.swiper-button-next').classList.add(`swiper-next-${index}`);
  container.querySelector('.swiper-button-prev').classList.add(`swiper-prev-${index}`);
});

swipers.forEach((swiper, index) => {
  new Swiper(`.swiper-${index}`, {
    loop: true,
    navigation: {
      nextEl: `.swiper-next-${index}`,
      prevEl: `.swiper-prev-${index}`,
    },
  });
});

/* Slider vins-slide */
var vins_slide = new Swiper('.vins-slide-swiper', {
  slidesPerView: 'auto',
  spaceBetween: 0,
  centeredSlides: true,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  breakpoints: {
    // when window width is >= 576px
    576: {
      centeredSlides: false,
      spaceBetween: 24,
    },
  },
});

/* Slider homeblog */
var homeblog_slide = new Swiper('.homeblog-swiper', {
  slidesPerView: '1',
  spaceBetween: 0,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  breakpoints: {
    768: {
      slidesPerView: '2',
      spaceBetween: 40,
    },
    1200: {
      slidesPerView: '3',
      spaceBetween: 40,
    },
  },
});

/* Slider WC single product */
var wc_product_slide = new Swiper('.wc-product-swiper', {
  slidesPerView: '1',
  spaceBetween: 0,
  loop: true,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
});

/* Timeline histoire */
var timeline_history_slide = new Swiper('.timeline-history-swiper', {
  slidesPerView: 'auto',
  spaceBetween: 50,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  scrollbar: {
    el: ".swiper-scrollbar",
    draggable: true,
  },
});

/* Timeline terroir */
var timeline_terroir_slide = new Swiper('.timeline-terroir-swiper', {
  slidesPerView: 'auto',
  spaceBetween: 140,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  scrollbar: {
    el: ".swiper-scrollbar",
    draggable: true,
  },
});

/* Simple slide */
var simple_slide = new Swiper('.simple-slide-swiper', {
  slidesPerView: 'auto',
  spaceBetween: 50,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
});

/* Offres slide (visites et séminaires) */
var offre_slide = new Swiper('.offres-swiper', {
  slidesPerView: 'auto',
  spaceBetween: 50,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  scrollbar: {
    el: ".swiper-scrollbar",
    draggable: true,
  },
});