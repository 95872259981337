var hamburger = $('.hamburger');
var offcanvas = $('.navbar .offcanvas');
var anchorMenu = $('.anchor-menu');

hamburger.on('click', () => {
  hamburger.toggleClass('is-active');
  offcanvas.toggleClass('show');
});

anchorMenu.on('click', () => {
  setTimeout(() => {
    hamburger.click();
  }, 1200);
});

offcanvas.on('hidden.bs.offcanvas', () => {
  hamburger.removeClass('is-active');
});
